import request from '@/api/request'
import download from '@/api/download'

export function add(data) {
  return request({
      url: '/market-service/epc/balance/add',
      method: 'post',
      data
  })
}

export function edit(data) {
  return request({
      url: '/market-service/epc/balance/update',
      method: 'post',
      data,
  })
}

export function fetchDetail(params) {
  return request({
      url: `/market-service/epc/balance/query/${params.id}`,
  })
}

// 下载模版
export function downloadTemplate(params) {
  return download({
      url: `/market-service/epc/balance/export/template?type=${params}`,
  })
}
// 导出
export function exportFile(data) {
  return download({
      url: `/market-service/epc/balance/export`,
      data,
  })
}

