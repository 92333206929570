<template>
  <div>
    <Pane :bottom="0" :showRemarks="false">
      <a-tabs
        v-model="activeKey"
        :tabBarStyle="{
          margin: '0',
        }"
      >
        <a-tab-pane key="0" :tab="stageName"> </a-tab-pane>
      </a-tabs>

      <template v-slot:extra>
        <div class="export-download">
          <div @click="visible = true" class="progress">
            <img src="@/assets/epc/progress.jpg" alt="" />
            <a>流程查看</a>
          </div>
        </div>
      </template>
    </Pane>

    <div class="container">
      <a-form
        :form="form"
        @submit="handleSubmit"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
      >
        <a-row>
          <a-col>
            <a-form-item
              label="当前项目"
              class="default-highlight"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-input :disabled="true" :placeholder="designCode"></a-input>
            </a-form-item>
          </a-col>
          <a-col>
            <a-form-item
              label="工程名称"
              class="default-highlight"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-input :disabled="true" :placeholder="projectName" />
            </a-form-item>
          </a-col>

          <a-col>
            <a-form-item
              label="预计总金额(元)"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-input-number
                :formatter="$inputNumberFormat"
                v-decorator="[
                  'estTotalAmt',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>

          <a-col>
            <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
              <span slot="label" class="">附件</span>
              <div
                class="link-list"
                v-decorator="[
                  'file',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请上传附件！',
                      },
                    ],
                  },
                ]"
              >
                <a-checkbox-group
                  :value="selectedFileList"
                  @change="onSelectFile"
                  v-if="fileList.length !== 0"
                >
                  <div class="link" v-for="item in fileList" :key="item.id">
                    <a-checkbox :value="item.id">
                      <a target="_blank" download :href="item.completePath">{{
                        item.name
                      }}</a>
                    </a-checkbox>
                  </div>
                </a-checkbox-group>
                <div
                  style="color: #ccc"
                  v-else-if="fileList.length === 0 && id"
                >
                  无
                </div>
                <div style="color: #ccc; line-height: 1.5" v-else>
                  《剩余物资清单》<br />
                  《办公设施清单》
                </div>

                <div class="control-bar">
                  <FileUpload @uploaded="uploaded">
                    <div class="control">
                      <a-icon type="upload" />
                    </div>
                  </FileUpload>

                  <div class="control" @click="deleteFile">
                    <a-icon type="delete" />
                  </div>
                </div>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <div class="center">
          <a-space>
            <a-button @click="$close($route.path)">关闭</a-button>
            <a-button htmlType="submit" type="primary" v-if="!isView"
              >提交</a-button
            >
          </a-space>
        </div>
      </a-form>
    </div>
    <a-modal
      :visible="visible"
      title="流程图"
      dialogClass="modal"
      cancelText="关闭"
      :footer="null"
      @cancel="visible = false"
    >
      <img :src="imgUrl" style="width: 50%" />
    </a-modal>
  </div>
</template>

<script>
import { fetchDetail } from "@/api/epc";
import {
  add,
  edit,
  fetchDetail as fetchFileDetail,
} from "@/api/epc/income-expense";

import { mapGetters } from "vuex";

import FileUpload from "@/components/file-upload";

export default {
  name: "incExpSur",
  components: {
    FileUpload,
  },
  data() {
    return {
      visible: false,
      imgUrl: require("@/assets/epc/approvalFlow/5-7surplus.png"),
      activeKey: "0",
      stage: "epc_balance_remain_material_handling",
      isView: false,

      form: this.$form.createForm(this),
      pid: "", // 项目id
      id: "", //当前表单的id
      projectName: "",
      designCode: "",

      fileList: [],
      selectedFileList: [],
    };
  },

  computed: {
    ...mapGetters("setting", ["findSingleDictName"]),

    stageName() {
      return this.findSingleDictName("epc_stage", this.stage);
    },
  },

  mounted() {
    const { query } = this.$route;
    const { pid, id, hid, isView } = query || {};

    this.pid = pid;
    this.id = id;
    this.hid = hid;
    this.isView = Boolean(isView) || false;

    if (this.id) {
      this.getDetail();
    }
    this.getName();
  },
  methods: {
    getDetail() {
      fetchFileDetail({ id: this.id }).then((res) => {
        if (!res.id) return;

        this.form.setFieldsValue({
          estTotalAmt: res.estTotalAmt,
        });

        if (res.attachments) {
          this.form.setFieldsValue({
            file: res.attachments,
          });
          this.fileList = res.attachments;
        }
      });
    },
    getName() {
      fetchDetail(this.pid).then((res) => {
        this.projectName = res.name;
        this.designCode = res.designCode;
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // 如果没有id ，则新增
          delete values.file;

          if (!this.id) {
            console.log("新增");
            add({
              ...values,
              pid: this.pid,
              projectName: this.projectName,
              designCode: this.designCode,
              attachments: this.fileList,
              type: this.stage,
            }).then(() => {
              this.fileList = [];
              this.form.resetFields();
            });
          } else {
            edit({
              ...values,
              pid: this.pid,
              id: this.id,
              historyId: this.hid,
              projectName: this.projectName,
              designCode: this.designCode,
              attachments: this.fileList,
            });
          }
        }
      });
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];

      if (this.fileList.length > 0) {
        this.form.setFieldsValue({
          file: true,
        });
      }
    },
    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.fileList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
      this.selectedFileList = [];

      if (this.fileList.length === 0) {
        this.form.setFieldsValue({
          file: "",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 20px;
}

.center {
  margin-top: 80px;
}
</style>
